.minimize-button{
  font-size: 2em;
}

.upload-modal {
  position: fixed;
  bottom: 5px; /* Positioned at the bottom right corner */
  right: 20px;
  width: 300px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  z-index: 1000; /* Ensure it's above other content */
}

.upload-modal-header {
  display: flex;
  justify-content: flex-end;
  padding: 8px; /* Adjust padding as needed */
  height: 30px; /* Set a fixed height for the header */
  background-color: rgb(232, 228, 228);
}

.minimize-button,
.close-button {
  border: none;
  background: none;
  cursor: pointer;
  font-size: 16px;
  display: flex;
  align-items: center; /* Align text/icons vertically */
  justify-content: center;
  height: 100%; /* Match the height of the header */
  padding: 0 8px; /* Padding on the sides for clickable area */
}

.minimize-button:hover,
.close-button:hover {
  background-color: #f3f3f3;
}

.upload-modal-content {
  padding: 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.file-status-text {
  font-size: 16px;
  color: #5f6368;
}

.progress-container {
  position: relative;
  display: flex;
  align-items: center;
}

.progress-circle {
  width: 36px;
  height: 36px;
  transform: rotate(-90deg);
}

.progress-background,
.progress-bar {
  fill: none;
  stroke-width: 4;
}

.progress-background {
  stroke: #e0e0e0;
}

.progress-bar {
  stroke: #4F46E5;
  stroke-linecap: round;
  transition: stroke-dashoffset 0.3s linear;
}

.progress-text {
  margin-left: 8px;
  font-size: 14px;
  color: #5f6368;
}
